import React from "react"

export default function ResumeEmployment(props) {
  return (
    <section className="resumeEmployment">
      <a href={props.employerLink} target="_blank" rel="noreferrer" className="employerName externalLink"><h3>{props.employerName}</h3></a>
      
      <div className="roleDetails">
        <h4 className="employmentRole">{props.employmentRole}</h4>
        <h5 className="employmentDuration">{props.employmentDuration}</h5>
      </div>
      
      <p className="employmentDescription">{props.employmentDescription}</p>
    </section>
  )
}


